import { UserManager } from 'oidc-client'

const stsAuthority = process.env.REACT_APP_AUTH_URL
const clientId = process.env.REACT_APP_CLIENT_ID
const clientRoot = process.env.REACT_APP_CLIENT_ROOT
const clientScope = process.env.REACT_APP_CLIENT_SCOPE

const settings = {
  authority: stsAuthority,
  client_id: clientId,
  redirect_uri: `${clientRoot}/loginCallback.html`,
  silent_redirect_uri: clientRoot,
  post_logout_redirect_uri: clientRoot,
  response_type: 'code',
  scope: clientScope,
}
let userManager = new UserManager(settings)

export function getUser() {
  console.log('Here I am 2')
  return userManager.getUser()
}

export function login() {
  return userManager.signinRedirect()
}

export function renewToken() {
  return userManager.signinSilent()
}

export function logout() {
  const idToken = window.sessionStorage.getItem('idToken')
  return userManager.signoutRedirect({ id_token_hint: idToken })
}

export function getRequestHeaders(authToken) {
  return {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + authToken,
  }
}
