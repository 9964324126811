import React from 'react'
import { Link } from 'react-router-dom'
import ttdlogo from '../assets/images/ttd-logo-white.svg'
import { withRouter } from 'react-router-dom'
import { logout } from '../services/AuthService'
import applogout from '../assets/images/logout.svg'
import './main.css'

class Home extends React.Component {
  render() {
    const { userCredential } = this.props
    return (
      <div>
        <div className="grid-container-audiences">
          <div className="navbar">
            <div className="ttdlogo">
              <img className="ttdlogo" alt="ttd-logo" src={ttdlogo} />
            </div>
            <div className="applogout">
              <img
                className="applogout"
                alt="app-logout"
                src={applogout}
                onClick={() => logout()}
              />
            </div>
          </div>
          <div className="headerarea">
            <div className="welcome">
              <p>Welcome! {userCredential.username || ''}</p>
            </div>
            <div className="line"></div>
            <div className="heading">
              <p>
                <span className="highlight">Home</span>
              </p>
            </div>
            <div className="line"></div>
          </div>
          <div className="formarea">
            <ul>
              <li>
                <Link className="hypelink" to="/spendoverride">
                  Variable Pacing - % Daily Spend Override
                </Link>
              </li>
              <li>
                <Link className="hypelink" to="/dailyspendoverride">
                  Static Daily Caps - Bulk Editing by Flight
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Home)
