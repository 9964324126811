import React, { useEffect, useState } from 'react'
import { CLIENT_SETTINGS, TTD_API_PATH } from '../constants/api-path'
import { getRequestHeaders, getUser, login } from '../services/AuthService'
import { useLocation } from '@ttd/maui/mocks/react-router-dom'

const AppContext = React.createContext()

const AppProvider = ({ children }) => {
  const location = useLocation()
  const [userCredential, setUserCredential] = useState({
    isLoggedIn: false,
    username: '',
    authToken: '',
  })
  const [clientSettings, setClientSettings] = useState({ popupSettings: {}, dailyOverride: {} })
  const [partners, setPartners] = useState([])

  const getPartners = (authToken) => {
    const partnerRequestOptions = {
      method: 'POST',
      headers: getRequestHeaders(authToken),
      body: JSON.stringify({ PageStartIndex: 0, PageSize: null }),
    }

    fetch(TTD_API_PATH.PARTNER_QUERY, partnerRequestOptions)
      .then((res) => res.json())
      .then((data) => {
        setPartners(data.Result || [])
      })
      .catch(console.log)
  }

  const getClientSettings = () => {
    fetch(CLIENT_SETTINGS, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((clientSettings) => {
        setClientSettings(clientSettings)
      })
      .catch((error) => {
        console.log(error)
        setClientSettings({
          popupSettings: { isEnabled: false },
          dailyOverride: {},
        })
      })
  }

  const authenticateUser = () => {
    getUser().then((user) => {
      if (user) {
        setUserCredential({
          isLoggedIn: true,
          username: user.profile.name,
          authToken: user.access_token,
          idToken: user.id_token,
        })
        window.sessionStorage.setItem('idToken', user.id_token)
        window.sessionStorage.setItem('authToken', user.access_token)
        window.sessionStorage.setItem('username', user.profile.name)
      } else {
        window.sessionStorage.removeItem('username')
        window.sessionStorage.removeItem('authToken')
        window.sessionStorage.removeItem('idToken')
        login()
      }
    })
  }

  useEffect(() => {
    if (location.pathname !== '/loginCallback.html') {
      authenticateUser()
    }
    getClientSettings()
  }, [location.pathname])

  useEffect(() => {
    if (userCredential.isLoggedIn && userCredential.authToken) {
      getPartners(userCredential.authToken)
    }
  }, [userCredential])

  return (
    <AppContext.Provider
      value={{
        userCredential,
        clientSettings,
        partners,
      }}
    >
      {userCredential.isLoggedIn && children}
    </AppContext.Provider>
  )
}

export default AppContext
export { AppProvider }
