export const TTD_API_BASE_URL = process.env.REACT_APP_TTD_API_BASE_URL
export const SO_API_BASE_URL = process.env.REACT_APP_SO_API_BASE_URL

export const CLIENT_SETTINGS = '/client-settings.json'

export const TTD_API_PATH = {
  CAMPAIGN_QUERY_ADVERTISER: `${TTD_API_BASE_URL}/campaign/query/advertiser`,
  PARTNER_QUERY: `${TTD_API_BASE_URL}/partner/query`,
  ADVERTISER_QUERY_PARTNER: `${TTD_API_BASE_URL}/advertiser/query/partner`,
}

export const SO_API_PATH = {
  CONFIG: `${SO_API_BASE_URL}/config`,
  DAILY_CONFIG: `${SO_API_BASE_URL}/daily/config`,
  CAMPAIGN_LIST_WITH_OVERRIDE: (advertiserId) =>
    `${SO_API_BASE_URL}/config/${advertiserId}/campaignListWithOverride`,
}
