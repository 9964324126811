import React from 'react'

const Spinner = ({ isShow }) => {
  return (
    isShow && (
      <div className="spin-container">
        <div className="spinner"></div>
      </div>
    )
  )
}

export default Spinner
