import React from 'react'
import FormErrorMessage from './form-error-message'

const InputNumber = ({ suffix, errorMessage, ...props }) => {
  return (
    <div className="input-number">
      <input {...props} type={'number'} />
      {suffix && <div className="suffix">{suffix}</div>}
      <FormErrorMessage message={errorMessage} />
    </div>
  )
}

export default InputNumber
