import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import 'styles/base.scss'
import './assets/scss/index.scss'

import Home from './components/Home'
import CampaignSpendOverride from './components/CampaignSpendOverride'
import DailyCampaignSpendOverride from './components/DailyCampaignSpendOverride'
import AppContext, { AppProvider } from './context/AppContext'

function App() {
  return (
    <AppProvider>
      <main>
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/"
              render={() => (
                <AppContext.Consumer>{(context) => <Home {...context} />}</AppContext.Consumer>
              )}
            />
            <Route
              path="/home"
              render={() => (
                <AppContext.Consumer>{(context) => <Home {...context} />}</AppContext.Consumer>
              )}
            />
            <Route
              path="/spendoverride"
              render={() => (
                <AppContext.Consumer>
                  {(context) => <CampaignSpendOverride {...context} />}
                </AppContext.Consumer>
              )}
            />
            <Route
              path="/dailyspendoverride"
              render={() => (
                <AppContext.Consumer>
                  {(context) => <DailyCampaignSpendOverride {...context} />}
                </AppContext.Consumer>
              )}
            />
          </Switch>
        </BrowserRouter>
      </main>
    </AppProvider>
  )
}

export default App
