import React from 'react'
import './main.css'
import ttdlogo from '../assets/images/ttd-logo-white.svg'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Moment from 'moment'
import { Link, withRouter } from 'react-router-dom'
import Button, { BUTTON_TYPE } from '@ttd/maui/lib/components/display/Button'
import applogout from '../assets/images/logout.svg'
import { getRequestHeaders, logout } from '../services/AuthService'
import { SO_API_PATH, TTD_API_PATH } from '../constants/api-path'
import InputNumber from './input-number'
import FormErrorMessage from './form-error-message'
import { capitalizeFirstLetter, splitStringByCapitalLetter } from '../utils/string'
import Spinner from './spinner'
import NavigateConfirmation from './NavigateConfirmation'
import Select, { CustomOption } from './select'
import { NEW_ADVERTISER_NAME, NEW_PARTNER_NAME } from '../constants/message-variable'

const initFormDoWValues = {
  monday: {
    value: 0,
    errorMessage: null,
  },
  tuesday: {
    value: 0,
    errorMessage: null,
  },
  wednesday: {
    value: 0,
    errorMessage: null,
  },
  thursday: {
    value: 0,
    errorMessage: null,
  },
  friday: {
    value: 0,
    errorMessage: null,
  },
  saturday: {
    value: 0,
    errorMessage: null,
  },
  sunday: {
    value: 0,
    errorMessage: null,
  },
}

class CampaignSpendOverride extends React.Component {
  resetForm(
    fieldNames,
    options = {
      emptyDate: false,
    }
  ) {
    let resetFields = {}
    fieldNames &&
      fieldNames.forEach((name) => {
        switch (name) {
          case 'partner':
          case 'advertiser':
            resetFields[name] = {
              value: null,
              errorMessage: null,
            }
            break
          case 'campaign':
            resetFields[name] = {
              value: [],
              errorMessage: null,
            }
            break
          case 'startDate':
          case 'endDate':
            resetFields[name] = {
              value: options.emptyDate ? null : new Date(),
              errorMessage: null,
            }
            break
          case 'reportName':
            resetFields[name] = {
              value: '',
              errorMessage: null,
            }
            break
          case 'dayOfWeek':
            resetFields = { ...resetFields, ...initFormDoWValues }
            break
          default:
        }
      })

    this.setState((preState) => ({
      formData: {
        ...preState.formData,
        ...resetFields,
      },
    }))
  }

  validateFormChanged() {
    const { formData, isFormDataChanged } = this.state
    let isDataChanged = false
    Object.keys(formData).forEach((key) => {
      switch (key) {
        case 'partner':
        case 'advertiser':
          if (formData[key].value && formData[key].value.value) isDataChanged = true
          break
        case 'campaign':
          if (formData[key].value && formData[key].value.length > 0) isDataChanged = true
          break

        case 'monday':
        case 'tuesday':
        case 'wednesday':
        case 'thursday':
        case 'friday':
        case 'saturday':
        case 'sunday':
          if (
            formData[key].value !== '' &&
            formData[key].value !== 0 &&
            !isNaN(Number(formData[key].value))
          )
            isDataChanged = true
          break
        default:
      }
    })
    if (isDataChanged === isFormDataChanged) return

    this.setState({
      isFormDataChanged: isDataChanged,
    })
  }

  validateFieldValue(key, value) {
    switch (key) {
      case 'partner':
      case 'advertiser':
        if (!value) return `${capitalizeFirstLetter(splitStringByCapitalLetter(key))} is required.`
        break

      case 'campaign':
        if (!value || value.length <= 0)
          return `${capitalizeFirstLetter(splitStringByCapitalLetter(key))} is required.`
        break

      case 'startDate':
      case 'endDate':
        if (!this.isRevertOverride() && !value)
          return `${capitalizeFirstLetter(splitStringByCapitalLetter(key))} is required.`
        if (value && !value.getTime())
          return `${capitalizeFirstLetter(splitStringByCapitalLetter(key))} is invalid.`
        break

      case 'monday':
      case 'tuesday':
      case 'wednesday':
      case 'thursday':
      case 'friday':
      case 'saturday':
      case 'sunday':
        if (value === '')
          return `Override is required for ${capitalizeFirstLetter(
            splitStringByCapitalLetter(key)
          )}`
        if (Number.isNaN(value) || Number(value) < -100 || Number(value) > 100)
          return 'Invalid value (ranges between -100% to 100%)'
        if (!Number.isInteger(Number(value)))
          return 'Invalid value (only whole numbers are allowed)'
        break
      default:
    }
  }

  handleInputChange(key, value) {
    const errorMessage = this.validateFieldValue(key, value)
    this.setState((preState) => ({
      formData: {
        ...preState.formData,
        [key]: {
          value,
          errorMessage: errorMessage,
        },
      },
    }))
  }

  handleDateChange(key, value) {
    const errorMessage = this.validateFieldValue(key, value)
    const newFormData = {
      [key]: {
        value,
        errorMessage: errorMessage,
      },
    }

    if (
      key === 'startDate' &&
      value &&
      this.state.formData.endDate.value &&
      value.setHours(0, 0, 0, 0) - this.state.formData.endDate.value.setHours(0, 0, 0, 0) > 0
    ) {
      const endDateErrorMessage = this.validateFieldValue('endDate', null)
      newFormData.endDate = {
        value: null,
        errorMessage: endDateErrorMessage,
      }
    }

    this.setState((preState) => ({
      formData: {
        ...preState.formData,
        ...newFormData,
      },
    }))
  }

  handleDoWChange(key, value) {
    const errorMessage = this.validateFieldValue(key, value)

    this.setState(
      (preState) => {
        const newState = {
          formData: {
            ...preState.formData,
            [key]: {
              value,
              errorMessage: errorMessage,
            },
          },
        }

        if (value !== 0 && !this.validateFieldValue(key, value)) {
          newState.submitButtonMessage = null
        }

        return newState
      },
      () => {
        const { formData } = this.state
        const startDateErrorMessage = this.validateFieldValue('startDate', formData.startDate.value)
        const endDateErrorMessage = this.validateFieldValue('endDate', formData.endDate.value)
        if (
          startDateErrorMessage !== formData.startDate.errorMessage ||
          endDateErrorMessage !== formData.endDate.errorMessage
        ) {
          this.setState({
            formData: {
              ...formData,
              startDate: {
                ...formData.startDate,
                errorMessage: startDateErrorMessage,
              },
              endDate: {
                ...formData.endDate,
                errorMessage: endDateErrorMessage,
              },
            },
          })
        }
      }
    )
  }

  onChangeCampaignName(selectedOptions) {
    const { formData } = this.state
    const errorMessage = this.validateFieldValue('campaign', selectedOptions)
    const resetFields = ['reportName', 'startDate', 'endDate']

    if (selectedOptions.length === 0 || formData.campaign.value.length === 0) {
      resetFields.push('dayOfWeek')
    }
    this.resetForm(resetFields)

    this.setState((preState) => ({
      showRevertMessage: false,
      submitButtonMessage: null,
      formData: {
        ...preState.formData,
        campaign: {
          value: selectedOptions,
          errorMessage,
        },
      },
    }))

    if (errorMessage) return

    if (selectedOptions.length >= 1 && selectedOptions[0].isOverride) {
      this.getCampaignData(selectedOptions[0].value)
    }
  }

  renderDoW() {
    const { formData } = this.state

    const dayOfWeekKeys = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ]
    return dayOfWeekKeys.map((day) => (
      <tr className="tr" key={day}>
        <td>{capitalizeFirstLetter(day)}:</td>
        <td>
          <InputNumber
            name={day}
            disabled={!formData.campaign.value || formData.campaign.value.length <= 0}
            min={-100}
            max={100}
            step={10}
            suffix={'%'}
            pattern="[0-9]"
            value={formData[day].value}
            onChange={(event) => this.handleDoWChange(day, event.target.value)}
            errorMessage={formData[day].errorMessage}
          />
        </td>
      </tr>
    ))
  }

  render() {
    const {
      formData,
      isLoading,
      isFormDataChanged,
      campaignOptions,
      advertisers,
      isFormValid,
      showRevertMessage,
      submitButtonMessage,
    } = this.state
    const { clientSettings, partners, userCredential } = this.props
    return (
      <div>
        <NavigateConfirmation isShow={isFormDataChanged} />
        <div className="grid-container-audiences">
          <div className="navbar">
            <div className="ttdlogo">
              <img className="ttdlogo" alt="ttd-logo" src={ttdlogo} />
            </div>
            <div className="applogout">
              <img
                className="applogout"
                alt="app-logout"
                src={applogout}
                onClick={() => logout()}
              />
            </div>
          </div>
          <div className="headerarea">
            <div className="welcome">
              <p>Welcome {userCredential.username} </p>
            </div>
            <div className="back-to-hyperlink">
              <Link to={`/home`}>
                <Button
                  id="campaign-view__back-to-dashboard"
                  glyphBefore="back-arrow"
                  isFlat
                  type={BUTTON_TYPE.PRIMARY}
                >
                  BACK TO HOME PAGE
                </Button>
              </Link>
            </div>
            <div className="line"></div>
            <div className="heading">
              <p>
                <span className="highlight">Variable Pacing - % Daily Spend Override</span>
              </p>
            </div>
            <div className="line"></div>
          </div>
          <div className="formarea">
            <Spinner isShow={isLoading} />
            <form>
              <table className="table">
                <tr className="tr">
                  <td>Partner Name:</td>
                  <td>
                    <Select
                      width="400px"
                      value={formData.partner.value}
                      onChange={this.handlePartnerChange}
                      placeholder="Select"
                      hideSelectedOptions={false}
                      isClearable
                      isDisabled={partners.length <= 1}
                      options={partners.map((partner) => ({
                        value: partner.PartnerId,
                        label: partner.PartnerName,
                      }))}
                    />
                    <FormErrorMessage message={formData.partner.errorMessage} />
                  </td>
                </tr>
                <tr className="tr">
                  <td>Advertiser Name:</td>
                  <td>
                    <Select
                      width="400px"
                      value={formData.advertiser.value}
                      onChange={this.handleAdvertiserChange}
                      placeholder="Select"
                      hideSelectedOptions={false}
                      isClearable
                      options={advertisers.map((advertiser) => ({
                        value: advertiser.AdvertiserId,
                        label: advertiser.AdvertiserName,
                      }))}
                      components={{ Option: CustomOption }}
                      isDisabled={!formData.partner.value}
                    />
                    <FormErrorMessage message={formData.advertiser.errorMessage} />
                  </td>
                </tr>
                <tr className="tr">
                  <td>Campaign Name:</td>
                  <td>
                    <div className="campaign-container">
                      <div className="campaign-select-block">
                        <Select
                          width="400px"
                          value={formData.campaign.value}
                          onChange={this.onChangeCampaignName}
                          isMulti
                          placeholder="Select"
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          options={campaignOptions}
                          isDisabled={!formData.advertiser.value}
                        />
                        <Button
                          className="revert-button"
                          disabled={
                            !formData.campaign.value[0] || !formData.campaign.value[0].isOverride
                          }
                          onClick={this.resetOverride}
                        >
                          {clientSettings.dailyOverride.revertOverrideButtonText}
                        </Button>
                      </div>
                      <div className="campaign-message-block">
                        {formData.campaign.value.find((campaign) => campaign.isOverride) && (
                          <span
                            className="note campaign-override-message"
                            dangerouslySetInnerHTML={{
                              __html: clientSettings.dailyOverride.multipleCampaignsDisabledMessage,
                            }}
                          />
                        )}
                        {formData.campaign.value[0] && showRevertMessage && (
                          <span className="revert-message">
                            {clientSettings.dailyOverride.revertOverrideMessage}
                          </span>
                        )}
                      </div>
                    </div>
                    <FormErrorMessage message={formData.campaign.errorMessage} />
                  </td>
                </tr>
                <tr className="tr">
                  <td>Report Name:</td>
                  <td>
                    <input
                      type="text"
                      title="reportname"
                      placeholder="Spend Override"
                      value={formData.reportName.value}
                      onChange={(event) => this.handleInputChange('reportName', event.target.value)}
                      disabled
                    />
                    <div className="info">
                      <p>
                        <strong>&nbsp;&nbsp;INFO!</strong> Schedule MyReports Template Spend
                        Override !!!
                      </p>
                    </div>
                  </td>
                </tr>
                <tr className="tr">
                  <td className="datepicker-container">
                    <span className="datepicker-container__title">
                      Override &nbsp;&nbsp; From : &nbsp;&nbsp;
                    </span>
                    <div className="datepicker-container__content">
                      <DatePicker
                        selected={formData.startDate.value}
                        onChange={(value) => this.handleDateChange('startDate', value)}
                        name="startDate"
                        dateFormat="MM-dd-yyyy"
                        minDate={new Date()}
                      />
                      <FormErrorMessage message={formData.startDate.errorMessage} />
                    </div>
                  </td>
                  <td className="datepicker-container">
                    <span className="datepicker-container__title">
                      &nbsp;&nbsp;&nbsp;&nbsp; To : &nbsp;&nbsp;
                    </span>
                    <div className="datepicker-container__content">
                      <DatePicker
                        selected={formData.endDate.value}
                        onChange={(value) => this.handleDateChange('endDate', value)}
                        name="endDate"
                        dateFormat="MM-dd-yyyy"
                        minDate={
                          formData.startDate.value ? new Date(formData.startDate.value) : new Date()
                        }
                      />
                      <FormErrorMessage message={formData.endDate.errorMessage} />
                    </div>
                  </td>
                </tr>

                {this.renderDoW()}

                <tr className="tr">
                  <Button
                    id="form-submit"
                    type={BUTTON_TYPE.ACTION}
                    onClick={this.handleSubmit}
                    disabled={!isFormValid}
                  >
                    Submit
                  </Button>
                  {submitButtonMessage && (
                    <span className="submit-button-message">{submitButtonMessage}</span>
                  )}
                </tr>
              </table>
            </form>
          </div>
        </div>
      </div>
    )
  }

  getAdvertisers(partnerId) {
    const { authToken } = this.props.userCredential
    this.setState({
      isLoading: true,
    })
    const advertiserRequestOptions = {
      method: 'POST',
      headers: getRequestHeaders(authToken),
      body: JSON.stringify({
        PartnerId: partnerId,
        Availabilities: ['Available'],
        PageStartIndex: 0,
        PageSize: null,
      }),
    }

    fetch(TTD_API_PATH.ADVERTISER_QUERY_PARTNER, advertiserRequestOptions)
      .then((res) => res.json())
      .then((advData) => {
        this.setState({
          advertisers: advData.Result,
        })
      })
      .catch(console.log)
      .finally(() =>
        this.setState({
          isLoading: false,
        })
      )
  }

  getCampaigns(advertiserId) {
    const { authToken } = this.props.userCredential
    this.setState({
      isLoading: true,
    })
    const campaignRequestOptions = {
      method: 'GET',
      headers: getRequestHeaders(authToken),
    }

    fetch(SO_API_PATH.CAMPAIGN_LIST_WITH_OVERRIDE(advertiserId), campaignRequestOptions)
      .then((res) => res.json())
      .then((campaignData) => {
        this.setState({
          campaigns: campaignData,
          isLoading: false,
        })
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          isLoading: false,
        })
      })
  }

  handlePartnerChange(itemSelected) {
    const { clientSettings } = this.props
    const { formData } = this.state

    if (
      clientSettings.popupSettings.isEnabled &&
      formData.advertiser.value &&
      formData.partner.value &&
      !window.confirm(
        itemSelected
          ? clientSettings.popupSettings.updatePartnerMessage.change.replaceAll(
              NEW_PARTNER_NAME,
              itemSelected.label
            )
          : clientSettings.popupSettings.updatePartnerMessage.remove
      )
    )
      return

    const errorMessage = this.validateFieldValue('partner', itemSelected)
    this.resetForm(['advertiser', 'campaign', 'reportName', 'startDate', 'endDate', 'dayOfWeek'])

    this.setState((preState) => ({
      advertisers: [],
      campaigns: [],
      formData: {
        ...preState.formData,
        partner: {
          value: itemSelected,
          errorMessage,
        },
      },
    }))

    if (errorMessage) return

    this.getAdvertisers(itemSelected.value)
  }

  handleAdvertiserChange(itemSelected) {
    const { clientSettings } = this.props
    const { formData } = this.state

    if (
      clientSettings.popupSettings.isEnabled &&
      formData.campaign.value.length > 0 &&
      formData.advertiser.value &&
      !window.confirm(
        itemSelected
          ? clientSettings.popupSettings.updateAdvertiserMessage.change.replaceAll(
              NEW_ADVERTISER_NAME,
              itemSelected.label
            )
          : clientSettings.popupSettings.updateAdvertiserMessage.remove
      )
    )
      return

    const errorMessage = this.validateFieldValue('advertiser', itemSelected)
    this.resetForm(['campaign', 'reportName', 'startDate', 'endDate', 'dayOfWeek'])
    this.setState((preState) => ({
      campaigns: [],
      formData: {
        ...preState.formData,
        advertiser: {
          value: itemSelected,
          errorMessage,
        },
      },
    }))

    if (errorMessage) return

    this.getCampaigns(itemSelected.value)
  }

  getCampaignData(campaignId) {
    const { userCredential } = this.props
    this.setState({
      isLoading: true,
    })
    const requestOption = {
      headers: { username: userCredential.username },
    }
    fetch(
      `${SO_API_PATH.CONFIG}/${this.state.formData.advertiser.value.value}/${campaignId}`,
      requestOption
    )
      .then((res) => res.json())
      .then((data) => {
        if (null != data && (data === 'Config not found.' || data === 'Missing header value.')) {
          if (data === 'Missing header value.') {
            alert(data)
          }
        } else {
          const newFormDataState = JSON.parse(JSON.stringify({ ...this.state.formData }))
          newFormDataState.reportName.value = data.reportname
          newFormDataState.monday.value = data.monday
          newFormDataState.tuesday.value = data.tuesday
          newFormDataState.wednesday.value = data.wednesday
          newFormDataState.thursday.value = data.thursday
          newFormDataState.friday.value = data.friday
          newFormDataState.saturday.value = data.saturday
          newFormDataState.sunday.value = data.sunday

          const startDate = new Date(data.startdate)
          const endDate = new Date(data.enddate)

          this.setState({
            formData: {
              ...newFormDataState,
              startDate: {
                value: data.startdate && startDate.getTime() ? startDate : null,
                errorMessage: null,
              },
              endDate: {
                value: data.enddate && endDate.getTime() ? endDate : null,
                errorMessage: null,
              },
            },
          })
        }

        this.setState({
          isLoading: false,
        })
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          isLoading: false,
        })
      })
  }

  isRevertOverride() {
    const { formData } = this.state
    return (
      Number(formData.monday.value) === 0 &&
      Number(formData.tuesday.value) === 0 &&
      Number(formData.wednesday.value) === 0 &&
      Number(formData.thursday.value) === 0 &&
      Number(formData.friday.value) === 0 &&
      Number(formData.saturday.value) === 0 &&
      Number(formData.sunday.value) === 0
    )
  }

  validateFormData() {
    const { formData } = this.state
    const invalidData = {}
    Object.keys(formData).forEach((key) => {
      const errorMessage = this.validateFieldValue(key, formData[key].value)
      if (errorMessage) {
        invalidData[key] = { value: formData[key].value, errorMessage }
      }
    })

    return {
      isValid: Object.keys(invalidData).length <= 0,
      invalidFields: invalidData,
    }
  }

  handleSubmit(event) {
    const { formData, campaigns } = this.state
    const validationData = this.validateFormData()

    if (!validationData.isValid) {
      this.setState((preState) => ({
        formData: {
          ...preState.formData,
          ...validationData.invalidFields,
        },
      }))
      return
    }

    if (!formData.campaign.value[0].isOverride && this.isRevertOverride()) {
      this.setState({
        submitButtonMessage: 'At least 1 non-zero daily % overrides required',
      })
      return
    } else {
      this.setState({
        submitButtonMessage: null,
      })
    }

    const { userCredential } = this.props

    this.setState({
      isLoading: true,
    })
    event.preventDefault()

    const momentStartDate = Moment(formData.startDate.value).format('MM-DD-YYYY')
    const momentEndDate = Moment(formData.endDate.value).format('MM-DD-YYYY')

    const promises = formData.campaign.value.map((data) => {
      const submitPayload = {
        method: 'POST',
        headers: { username: userCredential.username },
        body: JSON.stringify({
          campaignid: data.value,
          advertiserid: formData.advertiser.value.value,
          startdate: momentStartDate === 'Invalid date' ? '' : momentStartDate,
          enddate: momentEndDate === 'Invalid date' ? '' : momentEndDate,
          monday: formData.monday.value,
          tuesday: formData.tuesday.value,
          wednesday: formData.wednesday.value,
          thursday: formData.thursday.value,
          friday: formData.friday.value,
          saturday: formData.saturday.value,
          sunday: formData.sunday.value,
          lastupdateddby: userCredential.username,
          username: userCredential.username,
          reportname: formData.reportName.value,
        }),
      }

      return fetch(SO_API_PATH.CONFIG, submitPayload)
    })

    Promise.allSettled(promises)
      .then((res) => {
        if (res.find((item) => !item.value.ok)) {
          let errorMessage = 'Failed to apply override for'
          res.forEach((item, index) => {
            if (!item.value.ok) {
              errorMessage += ` ${formData.campaign.value[index].campaignName},`
            }
          })
          this.setState({
            isLoading: false,
          })
          alert(errorMessage.substring(0, errorMessage.length - 1)) // remove trailing comma
        } else {
          const isRevertOverride = this.isRevertOverride()

          const updatedCampaigns = campaigns.map((campaign) => {
            if (formData.campaign.value.find((item) => item.value === campaign.CampaignId)) {
              return { ...campaign, IsOverride: !isRevertOverride }
            }
            return campaign
          })

          this.resetForm(['campaign', 'startDate', 'endDate', 'dayOfWeek'], { emptyDate: true })
          this.setState({
            isLoading: false,
            isFormDataChanged: false,
            showRevertMessage: false,
            campaigns: updatedCampaigns,
          })
          alert('Saved config')
        }
      })
      .catch((error) => {
        console.log('error', error)
        this.setState({
          isLoading: false,
        })
      })
  }

  setCampaignOptions() {
    const { campaigns, formData } = this.state

    let options = campaigns.map((campaign) => ({
      value: campaign.CampaignId,
      label: campaign.IsOverride ? `${campaign.CampaignName} (O)` : campaign.CampaignName,
      campaignName: campaign.CampaignName,
      isOverride: campaign.IsOverride,
    }))

    if (formData.campaign.value.length > 0) {
      const isOverrideSelected = formData.campaign.value[0].isOverride
      options = options
        .map((option) => ({
          ...option,
          isDisabled: isOverrideSelected
            ? formData.campaign.value[0].value !== option.value
            : option.isOverride,
        }))
        .sort((a, b) => a.isOverride - b.isOverride)

      if (isOverrideSelected) options.reverse()
    }

    this.setState({
      campaignOptions: options,
    })
  }

  resetOverride() {
    this.resetForm(['startDate', 'endDate', 'dayOfWeek'], { emptyDate: true })
    this.setState({ showRevertMessage: true })
  }

  handlePartnerListChanged() {
    const { partners } = this.props
    const newState = {}

    if (partners.length === 1) {
      newState.formData = {
        ...this.state.formData,
        partner: {
          value: {
            value: partners[0].PartnerId,
            label: partners[0].PartnerName,
          },
          errorMessage: null,
        },
      }
      this.getAdvertisers(partners[0].PartnerId)
    } else {
      newState.isLoading = false
    }

    this.setState(newState)
  }

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      advertisers: [],
      campaigns: [],
      campaignOptions: [],
      isFormDataChanged: false,
      isFormValid: false,
      showRevertMessage: false,
      submitButtonMessage: null,
      formData: {
        partner: {
          value: null,
          errorMessage: null,
        },
        advertiser: {
          value: null,
          errorMessage: null,
        },
        campaign: {
          value: [],
          errorMessage: null,
        },
        reportName: {
          value: 'Spend Override',
          errorMessage: null,
        },
        startDate: {
          value: new Date(),
          errorMessage: null,
        },
        endDate: {
          value: new Date(),
          errorMessage: null,
        },
        ...initFormDoWValues,
      },
    }
    this.handlePartnerChange = this.handlePartnerChange.bind(this)
    this.handleAdvertiserChange = this.handleAdvertiserChange.bind(this)
    this.onChangeCampaignName = this.onChangeCampaignName.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.validateFieldValue = this.validateFieldValue.bind(this)
    this.resetOverride = this.resetOverride.bind(this)
  }

  componentDidMount() {
    const { partners } = this.props
    if (partners && partners.length > 0) {
      this.handlePartnerListChanged()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.partners !== this.props.partners &&
      this.props.partners &&
      this.props.partners.length > 0
    ) {
      this.handlePartnerListChanged()
    }

    if (prevState.formData !== this.state.formData) {
      this.validateFormChanged()

      const validationData = this.validateFormData()
      if (validationData.isValid !== this.state.isFormValid) {
        this.setState({
          isFormValid: validationData.isValid,
        })
      }
    }

    if (
      prevState.campaigns !== this.state.campaigns ||
      prevState.formData.campaign !== this.state.formData.campaign
    ) {
      this.setCampaignOptions()
    }
  }
}

export default withRouter(CampaignSpendOverride)
