import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import './main.css'
import ttdlogo from '../assets/images/ttd-logo-white.svg'
import Button, { BUTTON_TYPE } from '@ttd/maui/lib/components/display/Button'
import applogout from '../assets/images/logout.svg'
import Download from '@axetroy/react-download'
import { getRequestHeaders, logout } from '../services/AuthService'
import { SO_API_PATH, TTD_API_PATH } from '../constants/api-path'
import Select, { CustomOption } from './select'
import FormErrorMessage from './form-error-message'
import NavigateConfirmation from './NavigateConfirmation'
import { capitalizeFirstLetter, splitStringByCapitalLetter } from '../utils/string'
import Spinner from './spinner'
import { NEW_PARTNER_NAME } from '../constants/message-variable'

class DailyCampaignSpendOverride extends React.Component {
  render() {
    const { formData, isFormDataChanged, campaigns, advertisers, isLoading, isFormValid } =
      this.state
    const { userCredential, partners } = this.props
    return (
      <div>
        <NavigateConfirmation isShow={isFormDataChanged} />
        <div class="grid-container-audiences">
          <div class="navbar">
            <div class="ttdlogo">
              <img className="ttdlogo" alt="ttd-logo" src={ttdlogo} />
            </div>
            <div class="applogout">
              <img
                className="applogout"
                alt="app-logout"
                src={applogout}
                onClick={() => logout()}
              />
            </div>
          </div>
          <div class="headerarea">
            <div className="welcome">
              <p>Welcome {userCredential.username} </p>
            </div>
            <div className="back-to-hyperlink">
              <Link to={`/home`}>
                <Button
                  id="campaign-view__back-to-dashboard"
                  glyphBefore="back-arrow"
                  isFlat
                  type={BUTTON_TYPE.PRIMARY}
                >
                  BACK TO HOME PAGE
                </Button>
              </Link>
            </div>
            <div className="line"></div>
            <div className="heading">
              <p>
                <span className="highlight">Static Daily Caps - Bulk Editing by Flight</span>
              </p>
            </div>
            <div className="line"></div>
          </div>
          <div class="formarea">
            <Spinner isShow={isLoading} />
            <table className="table">
              <tr className="tr">
                <td>Partner Name:</td>
                <td>
                  <Select
                    width="400px"
                    value={formData.partner.value}
                    onChange={this.handlePartnerChange}
                    placeholder="Select"
                    hideSelectedOptions={false}
                    isClearable
                    isDisabled={partners.length <= 1}
                    options={partners.map((partner) => ({
                      value: partner.PartnerId,
                      label: partner.PartnerName,
                    }))}
                  />
                  <FormErrorMessage message={formData.partner.errorMessage} />
                </td>
              </tr>
              <tr className="tr">
                <td>Advertiser Name:</td>
                <td>
                  <Select
                    width="400px"
                    value={formData.advertiser.value}
                    onChange={this.handleAdvertiserChange}
                    placeholder="Select"
                    hideSelectedOptions={false}
                    isClearable
                    options={advertisers.map((advertiser) => ({
                      value: advertiser.AdvertiserId,
                      label: advertiser.AdvertiserName,
                    }))}
                    components={{ Option: CustomOption }}
                    isDisabled={!formData.partner.value}
                  />
                  <FormErrorMessage message={formData.advertiser.errorMessage} />
                </td>
              </tr>
              <tr className="tr">
                <td>Campaign Name:</td>
                <td>
                  <Select
                    width="400px"
                    value={formData.campaign.value}
                    onChange={this.handleCampaignChange}
                    placeholder="Select"
                    hideSelectedOptions={false}
                    options={campaigns.map((campaign) => ({
                      value: campaign.CampaignId,
                      label: campaign.CampaignName,
                    }))}
                    components={{ Option: CustomOption }}
                    isDisabled={!formData.advertiser.value}
                  />
                  <FormErrorMessage message={formData.campaign.errorMessage} />
                </td>
              </tr>
              <tr>&nbsp;</tr>
              <tr>
                <div>
                  <h3>Template for reference</h3>
                  <Download file="CampaignOverride_Template.csv" content="Date(MM/DD/YYYY),Goal">
                    <Button id="file-download-btn" type={BUTTON_TYPE.PRIMARY} isEnabled={true}>
                      Download Template
                    </Button>
                  </Download>
                </div>
              </tr>
              <tr>&nbsp;</tr>
              <tr>
                <div>
                  <h3>Upload an Override CSV File !!!</h3>
                  <div>
                    <input type="file" onChange={this.onFileChange} />
                    <Button
                      id="file-upload-btn"
                      type={BUTTON_TYPE.ACTION}
                      disabled={!isFormValid}
                      onClick={this.onFileUpload}
                    >
                      Upload
                    </Button>
                  </div>
                  {this.fileData()}
                </div>
              </tr>
            </table>
          </div>
        </div>
      </div>
    )
  }

  downloadTxtFile = () => {
    const element = document.createElement('a')
    const file = new Blob([document.getElementById('input').value], {
      type: 'text/plain;charset=utf-8',
    })
    element.href = URL.createObjectURL(file)
    element.download = 'myFile.txt'
    document.body.appendChild(element)
    element.click()
  }

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      advertisers: [],
      campaigns: [],
      isFormDataChanged: false,
      isFormValid: false,
      formData: {
        partner: {
          value: null,
          errorMessage: null,
        },
        advertiser: {
          value: null,
          errorMessage: null,
        },
        campaign: {
          value: null,
          errorMessage: null,
        },
        csvFile: {
          value: null,
          errorMessage: null,
        },
      },
    }
    this.handleAdvertiserChange = this.handleAdvertiserChange.bind(this)
    this.handlePartnerChange = this.handlePartnerChange.bind(this)
    this.handleCampaignChange = this.handleCampaignChange.bind(this)
    this.onFileChange = this.onFileChange.bind(this)
  }

  validateFormChanged() {
    const { formData, isFormDataChanged } = this.state
    let isDataChanged = false
    Object.keys(formData).forEach((key) => {
      switch (key) {
        case 'partner':
        case 'advertiser':
        case 'campaign':
          if (formData[key].value && formData[key].value.value) isDataChanged = true
          break
        default:
      }
    })
    if (isDataChanged === isFormDataChanged) return

    this.setState({
      isFormDataChanged: isDataChanged,
    })
  }

  validateFieldValue(key, value) {
    switch (key) {
      case 'partner':
      case 'advertiser':
      case 'campaign':
        if (!value) return `${capitalizeFirstLetter(splitStringByCapitalLetter(key))} is required.`
        break
      default:
    }
  }

  handlePartnerListChanged() {
    const { partners } = this.props
    const newState = {}

    if (partners.length === 1) {
      newState.formData = {
        ...this.state.formData,
        partner: {
          value: {
            value: partners[0].PartnerId,
            label: partners[0].PartnerName,
          },
          errorMessage: null,
        },
      }
      this.getAdvertisers(partners[0].PartnerId)
    } else {
      newState.isLoading = false
    }

    this.setState(newState)
  }

  getAdvertisers(partnerId) {
    const { userCredential } = this.props
    this.setState({
      isLoading: true,
    })
    const advertiserRequestOptions = {
      method: 'POST',
      headers: getRequestHeaders(userCredential.authToken),
      body: JSON.stringify({
        PartnerId: partnerId,
        Availabilities: ['Available'],
        PageStartIndex: 0,
        PageSize: null,
      }),
    }

    fetch(TTD_API_PATH.ADVERTISER_QUERY_PARTNER, advertiserRequestOptions)
      .then((res) => res.json())
      .then((advData) => {
        this.setState({
          advertisers: advData.Result,
        })
      })
      .catch(console.log)
      .finally(() =>
        this.setState({
          isLoading: false,
        })
      )
  }

  getCampaigns(advertiserId) {
    const { userCredential } = this.props
    this.setState({
      isLoading: true,
    })
    const campaignRequestOptions = {
      method: 'POST',
      headers: getRequestHeaders(userCredential.authToken),
      body: JSON.stringify({
        AdvertiserId: advertiserId,
        Availabilities: ['Available'],
        PageStartIndex: 0,
        PageSize: null,
      }),
    }

    fetch(TTD_API_PATH.CAMPAIGN_QUERY_ADVERTISER, campaignRequestOptions)
      .then((res) => res.json())
      .then((campaignData) => {
        this.setState({
          campaigns: campaignData.Result,
          isLoading: false,
        })
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          isLoading: false,
        })
      })
  }

  handlePartnerChange(itemSelected) {
    const { clientSettings } = this.props
    const { formData } = this.state

    if (
      clientSettings.popupSettings.isEnabled &&
      formData.advertiser.value &&
      formData.partner.value &&
      !window.confirm(
        itemSelected
          ? clientSettings.popupSettings.updatePartnerMessage.change.replaceAll(
              NEW_PARTNER_NAME,
              itemSelected.label
            )
          : clientSettings.popupSettings.updatePartnerMessage.remove
      )
    )
      return

    const errorMessage = this.validateFieldValue('partner', itemSelected)
    this.setState((preState) => ({
      advertisers: [],
      campaigns: [],
      formData: {
        ...preState.formData,
        partner: {
          value: itemSelected,
          errorMessage,
        },
        advertiser: {
          value: null,
          errorMessage: null,
        },
        campaign: {
          value: null,
          errorMessage: null,
        },
      },
    }))

    if (errorMessage) return

    this.getAdvertisers(itemSelected.value)
  }

  handleAdvertiserChange(itemSelected) {
    const errorMessage = this.validateFieldValue('advertiser', itemSelected)
    this.setState((preState) => ({
      campaigns: [],
      formData: {
        ...preState.formData,
        advertiser: {
          value: itemSelected,
          errorMessage,
        },
        campaign: {
          value: null,
          errorMessage: null,
        },
      },
    }))

    if (errorMessage) return

    this.getCampaigns(itemSelected.value)
  }

  handleCampaignChange(selectedOption) {
    const errorMessage = this.validateFieldValue('campaign', selectedOption)
    this.setState((preState) => ({
      formData: {
        ...preState.formData,
        campaign: {
          value: selectedOption,
          errorMessage,
        },
      },
    }))

    if (errorMessage) return
  }

  componentDidMount() {
    const { partners } = this.props
    if (partners && partners.length > 0) {
      this.handlePartnerListChanged()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.partners !== this.props.partners &&
      this.props.partners &&
      this.props.partners.length > 0
    ) {
      this.handlePartnerListChanged()
    }

    if (prevState.formData !== this.state.formData) {
      this.validateFormChanged()

      const validationData = this.validateFormData()
      if (validationData.isValid !== this.state.isFormValid) {
        this.setState({
          isFormValid: validationData.isValid,
        })
      }
    }
  }

  // On file select (from the pop up)
  onFileChange(event) {
    // Update the state
    this.setState({
      formData: {
        ...this.state.formData,
        csvFile: {
          value: event.target.files[0],
        },
      },
    })
  }

  validateFormData() {
    const { formData } = this.state
    const invalidData = {}
    Object.keys(formData).forEach((key) => {
      const errorMessage = this.validateFieldValue(key, formData[key].value)
      if (errorMessage) {
        invalidData[key] = { value: formData[key].value, errorMessage }
      }
    })

    return {
      isValid: Object.keys(invalidData).length <= 0,
      invalidFields: invalidData,
    }
  }

  // On file upload (click the upload button)
  onFileUpload = () => {
    const { userCredential } = this.props
    const { formData } = this.state

    const validationData = this.validateFormData()

    if (!validationData.isValid) {
      this.setState((preState) => ({
        formData: {
          ...preState.formData,
          ...validationData.invalidFields,
        },
      }))
      return
    }

    this.setState({
      isLoading: true,
    })

    const fileData = new FormData()
    fileData.append('data', formData.csvFile.value)

    const uploadRequest = {
      method: 'POST',
      headers: new Headers({
        username: userCredential.username,
      }),
      body: fileData,
    }
    fetch(
      `${SO_API_PATH.DAILY_CONFIG}/${formData.advertiser.value.value}/${formData.campaign.value.value}`,
      uploadRequest
    )
      .then((res) => res.json())
      .then((response) => {
        console.log(response)
        alert('Uploaded Successfully...')
      })
      .catch(function (error) {
        if (!formData.campaign.value || !formData.campaign.value.label) {
          alert(`Failed to apply override`)
        } else {
          alert(`Failed to apply override for ${formData.campaign.value.label}`)
        }
        console.log(error)
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
      })
  }

  fileData = () => {
    const { formData } = this.state
    if (formData.csvFile.value) {
      return (
        <div>
          <h2>File Details:</h2>
          <p>File Name: {formData.csvFile.value.name}</p>
          <p>File Type: {formData.csvFile.value.type}</p>
          <p>Last Modified: {formData.csvFile.value.lastModifiedDate.toDateString()}</p>
        </div>
      )
    } else {
      return (
        <div>
          <br />
          <h4>Choose a csv file before Pressing the Upload button</h4>
        </div>
      )
    }
  }
}

export default withRouter(DailyCampaignSpendOverride)
