import React from 'react';

const FormErrorMessage = ({message}) => {
  return (
    <div className='form-error-message'>
      {message}
    </div>
  );
};

export default FormErrorMessage;
