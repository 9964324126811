import React, { useEffect, useState } from 'react'
import ReactSelect, { components } from 'react-select'

const initContainerStyle = {
  margin: '4px 0',
  color: '#000000',
}

const initStyles = {
  container: (provided) => ({
    ...provided,
    ...initContainerStyle,
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: 'none',
    border: '1px solid #ccc',
    outline: state.isFocused ? '1px auto #000 !important' : 'none',
  }),
  option: (provided) => ({
    ...provided,
    '&:hover': {
      background: '#deebff',
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
}

const Select = ({ width, ...props }) => {
  const [styles, setStyles] = useState(initStyles)

  useEffect(() => {
    setStyles((preStyles) => {
      const newStyles = { ...preStyles }
      newStyles.container = (provided) => ({
        ...provided,
        ...initContainerStyle,
        width,
      })
      return newStyles
    })
  }, [setStyles, width])

  return <ReactSelect {...props} styles={styles} />
}

export default Select

export const CustomOption = ({ children, ...props }) => {
  // Improve performance when have a  number of option
  delete props.innerProps.onMouseMove
  delete props.innerProps.onMouseOver

  return <components.Option {...props}>{children}</components.Option>
}
