import React, { useContext, useEffect } from 'react'
import { Prompt } from 'react-router-dom'
import AppContext from '../context/AppContext'

const NavigateConfirmation = ({ isShow, message }) => {
  const { clientSettings } = useContext(AppContext)
  useEffect(() => {
    if (clientSettings.popupSettings.isEnabled) {
      if (isShow) {
        window.addEventListener('beforeunload', alertUser)
      } else {
        window.removeEventListener('beforeunload', alertUser)
      }
      return () => {
        window.removeEventListener('beforeunload', alertUser)
      }
    }
  }, [isShow, clientSettings.popupSettings.isEnabled])

  const alertUser = (e) => {
    e.preventDefault()
    e.returnValue = ''
  }

  return (
    <>
      {clientSettings.popupSettings.isEnabled && (
        <Prompt
          when={isShow}
          message={message || clientSettings.popupSettings.navigateAwayMessage}
        />
      )}
    </>
  )
}

export default NavigateConfirmation
